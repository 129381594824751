export const DiscordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Discord"
      role="img"
      viewBox="0 0 512 512"
      fill="#7289da"
    >
      <rect width="512" height="512" rx="15%" />
      <path
        d="m346 392-21-25c41-11 57-39 57-39-52 49-194 51-249 0 0 0 14 26 56 39l-23 25c-70-1-97-48-97-48 0-104 46-187 46-187 47-33 90-33 90-33l3 4c-58 16-83 42-83 42 68-46 208-42 263 0 1-1-33-28-86-42l5-4s43 0 90 33c0 0 46 83 46 187 0 0-27 47-97 48z"
        fill="#fff"
      />
      <ellipse cx="196" cy="279" rx="33" ry="35" />
      <ellipse cx="312" cy="279" rx="33" ry="35" />
    </svg>
  );
};
